<template>
	<div class="field">
		<label
			class="checkbox"
			:class="switcher ? 'switcher' : ''"
			:disabled="disabled === false ? null : disabled"
		>
			<input
				:id="id"
				type="checkbox"
				:name="name"
				:data-testid="dataTestid"
				:value="val"
				v-model="checked"
				@change="onChange"
				:disabled="disabled === false ? null : disabled"
			/>
			<span
				class="checkbox-label"
				:class="{ 'has-text-danger': !!error }"
				:style="labelColor ? `color: ${labelColor}` : null"
			>
				<slot>
					<span>{{ label }}</span>
					<em v-if="italicLabel">{{ italicLabel }}</em>
				</slot>
				<span
					class="icon ml-1"
					v-tooltip="{ content: tooltip, placement: tooltipPosition }"
					v-if="tooltip !== null"
				>
					<ws-icon size="sm" icon="info-circle" />
				</span>
			</span>
		</label>
		<p
			class="help mb-0"
			:class="{ 'is-danger': !!error }"
			v-if="labelUnderInput || $slots.labelUnderInput"
		>
			<span v-if="labelUnderInput" v-html="labelUnderInput" />
			<slot name="labelUnderInput" />
		</p>
		<p
			class="help is-danger"
			v-if="!!error && !!error !== false && error !== ''"
		>
			{{ error }}
		</p>
	</div>
</template>

<script>
import { generateRandomId } from "@/helpers/functions.helper.js";

export default {
	name: "WsFormCheckbox",

	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		// tricky to handle array with multiple checkboxes
		// https://forum.vuejs.org/t/using-v-model-on-multiple-checkbox-like-components-bound-to-the-same-array/22307/6
		val: {
			type: [String, Number, Boolean],
			default: false
		},
		modelValue: {
			type: [String, Number, Boolean, Array],
			default: false
		},
		label: {
			type: String,
			default: null
		},
		italicLabel: {
			type: String,
			default: null
		},
		switcher: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dataTestid: {
			type: null,
			default: ""
		},
		name: {
			type: String,
			default: null
		},
		tooltip: {
			type: String,
			default: null
		},
		tooltipPosition: {
			type: String,
			default: "top"
		},
		labelUnderInput: {
			type: String,
			default: null
		},
		error: {
			type: [String, Boolean],
			default: null
		},
		labelColor: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			checkedProxy: false
		};
	},
	emits: ["update:modelValue"],
	methods: {
		onChange() {
			return this.$emit("update:modelValue", this.checkedProxy);
		}
	},

	computed: {
		checked: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.checkedProxy = val;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
input[type="checkbox"] {
	width: 1rem;
	height: 1rem;
	margin-top: 2px;
}

.field {
	.checkbox {
		display: inline-flex;
		align-items: flex-start;
	}
}
.switcher {
	display: flex;
	align-content: center;

	input {
		display: none;

		+ span {
			transition: all 0.4s;
			display: inline-block;
			height: 1.2rem;
			width: 2.3rem;
			background: $color-grey-200;
			border-radius: 0.6rem;
			box-shadow: inset 1px 1px 1px $black-o10;
			position: relative;

			&::before {
				transition: all 0.4s;
				content: "";
				display: block;
				position: absolute;
				height: 1rem;
				width: 1rem;
				left: 0.1rem;
				top: 0.1rem;
				border-radius: 0.5rem;
				background: $white;
				box-shadow: 1px 1px 1px $black-o10;
			}
		}

		&:checked {
			+ span {
				background: $color-primary-500;

				&::before {
					left: auto;
					right: 0.1rem;
				}
			}
		}
	}

	.checkbox-label {
		display: block;
		font-size: 0.85rem;
		span {
			position: absolute;
			margin-left: 30px;
			text-align: left;
			min-width: 155px;
		}
	}
}

span.checkbox-label {
	font-weight: normal;
	margin-left: 5px;
	color: $default-text-color;
}
</style>

<template>
	<div
		class="ws-icon"
		:data-testid="icon"
		:class="{
			r0: rotate == '0',
			r45: rotate == '45',
			rm45: rotate == '-45',
			r90: rotate == '90',
			rm90: rotate == '-90',
			r180: rotate == '180',
			r270: rotate == '270',
			flipHorizontal: props.flipHorizontal,
			flipVertical: props.flipVertical,
			spin: props.spin
		}"
	>
		<em>
			<icon-async-component :width="iconSize" :height="iconSize" />
		</em>
	</div>
</template>

<script setup>
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import {
	onMounted,
	getCurrentInstance,
	computed,
	defineAsyncComponent,
	ref
} from "vue";

const { error: $logError } = useLogger();

const props = defineProps({
	icon: {
		type: String,
		default: null,
		required: true
	},
	size: {
		type: String,
		default: "1x",
		validator: function (value) {
			return [
				"xs",
				"sm",
				"md",
				"lg",
				"1x",
				"2x",
				"3x",
				"4x",
				"5x",
				"6x",
				"7x",
				"8x",
				"9x",
				"10x"
			].includes(value);
		}
	},
	rotate: {
		type: String,
		default: "0",
		validator: function (value) {
			if (!value) {
				return true;
			}
			return ["0", "45", "-45", "90", "-90", "180", "270"].includes(
				value.toString()
			);
		}
	},
	flipHorizontal: {
		type: Boolean,
		default: false
	},
	flipVertical: {
		type: Boolean,
		default: false
	},
	spin: {
		type: Boolean,
		default: false
	}
});

const hasParentWithIsSmallClass = ref(false);

onMounted(() => {
	try {
		const parent = getCurrentInstance().parent;
		if (parent?.vnode?.el?.classList) {
			hasParentWithIsSmallClass.value =
				parent?.vnode?.el?.classList?.contains("is-small");
		}
	} catch (error) {
		$logError("Error while checking for parent with is-small class", error);
	}
});

const iconSize = computed(() => {
	if (hasParentWithIsSmallClass.value) {
		return "16px";
	}

	switch (props.size) {
		case "xs":
			return "12px";
		case "sm":
			return "16px";
		case "md":
			return "20px";
		case "lg":
			return "28px";
		case "1x":
			return "24px";
		case "2x":
			return "32px";
		case "3x":
			return "48px";
		case "4x":
			return "64px";
		case "5x":
			return "80px";
		case "6x":
			return "96px";
		case "7x":
			return "112px";
		case "8x":
			return "128px";
		case "9x":
			return "144px";
		case "10x":
			return "160px";
		default:
			return "24px";
	}
});

const IconAsyncComponent = computed(() => {
	return (
		props.icon &&
		defineAsyncComponent({
			loader: async () => {
				return import(`./icons/${props.icon}.vue`).catch(() => {
					// as fontawesome icons are still in a different folder, we will load the icons from the ff-icons folder
					// TODO: move ff icons to the same folder as ws-icons when all icons are validated and good to go
					return import(`./icons/ff-icons/${props.icon}.vue`);
				});
			}
		})
	);
});
</script>

<style lang="scss" scoped>
@mixin iconrotate($angle) {
	em {
		transform: rotate($angle);
	}
}

em,
svg {
	display: flex;
	align-items: center;
	justify-content: center;
}

.r0 {
	@include iconrotate(0deg);
}

.r45 {
	@include iconrotate(45deg);
}

.rm45 {
	@include iconrotate(-45deg);
}

.r90 {
	@include iconrotate(90deg);
}

.rm90 {
	@include iconrotate(-90deg);
}

.r180 {
	@include iconrotate(180deg);
}

.r270 {
	@include iconrotate(270deg);
}

.flipHorizontal {
	transform: scaleX(-1);
}

.flipVertical {
	transform: scaleY(-1);
}

.spin {
	animation-name: spin;
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>

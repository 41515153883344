<template>
	<ws-form-label
		:id="`label-custom-selector-${generateRandomId()}`"
		:tooltip="props.tooltip"
		:error="props.error"
	>
		{{ props.label }}
	</ws-form-label>
	<div class="custom-selector" :data-testid="props.dataTestid">
		<div
			v-for="option in props.options"
			:key="option.value"
			@click="
				!props.disabled &&
					!option.disabled &&
					selectOption(option.value)
			"
			class="selector-option"
			:class="{
				selected: isSelectedOption(option.value),
				disabled: props.disabled || option.disabled
			}"
			:id="option.id"
			:data-testid="`${props.dataTestid}-${option.value.toLowerCase()}`"
		>
			<div id="option-label">
				{{ option.label }}
			</div>
			<span id="icon" v-if="isSelectedOption(option.value)">
				<ws-icon icon="check" />
			</span>
		</div>
	</div>
	<p class="help is-danger" v-if="!!props.error">{{ props.error }}</p>
</template>

<script setup>
import { computed } from "vue";
import { generateRandomId } from "@/helpers/functions.helper.js";
const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	modelValue: {
		type: String,
		default: null
	},

	options: {
		type: Array,
		required: true,
		validation: (value) => {
			if (!Array.isArray(value)) {
				return false;
			}
			for (const item of value) {
				const keys = Object.keys(item);
				if (!keys.includes("label") || !keys.includes("value")) {
					return false;
				}
			}

			return true;
		}
	},

	label: {
		type: String,
		default: null
	},
	tooltip: {
		type: String,
		default: null
	},
	error: {
		type: String,
		default: null
	},
	disabled: {
		type: Boolean,
		default: false
	},
	dataTestid: {
		type: String,
		default: "custom-selector"
	}
});

const selectedOption = computed(() => props.modelValue || null);

function isSelectedOption(option) {
	// in case there's no selectedOption (null), return false
	return selectedOption.value && selectedOption.value === option;
}

function selectOption(option) {
	emits("update:modelValue", option);
}
</script>

<style scoped lang="scss">
.custom-selector {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 16px;
	max-width: 700px;

	.selector-option {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 16px;
		border-radius: 6px;
		border: 1px solid $color-grey-300;
		cursor: pointer;

		&.selected {
			border-color: $color-grey-700;
			&.disabled {
				background-color: $color-grey-200;
			}
		}

		&:hover:not(.disabled) {
			box-shadow: $box-shadow-radio-card-hover;
		}

		&.disabled {
			border-color: $color-grey-300;
			cursor: not-allowed;
		}

		@include tablet {
			min-width: 315px;
		}

		div#option-label {
			@include tablet {
				width: 250px;
			}
		}
	}
}
</style>
